<template>
  <public-header></public-header>
  <div id="spacer"></div>
  <div id="content">
    <div id="mytitle">Teacher Login</div>
    <div v-if="error" class="error">{{ error }}</div>
    <form @submit.prevent="pressed">
      <div class="login">
        <input type="email" placeholder="Email" v-model="email" />
      </div>
      <div class="password">
        <input type="password" v-model="password" placeholder="Password" />
      </div>
      <div style="padding-top: 10px;">
        <button type="submit">Login</button>
      </div>
    </form>
    <br />New user?
    <router-link to="/register">Register for free teacher account</router-link>
    <br />Forgot password?
    <router-link to="/resetpw"> Send password reset link</router-link>
    <br /><br />
    <img
      alt="Google Login"
      src="../assets/images/btn_google_login.png"
      v-on:click="googleLogin"
    />
  </div>
</template>

<script>
import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import PublicHeader from '@/components/Public-Header';

export default {
  name: 'LoginView',
  components: { 'public-header': PublicHeader },
  data() {
    return {
      email: '',
      password: '',
      error: ''
    };
  },
  methods: {
    async pressed() {
      try {
        if (this.email.length == 0 || this.password.length == 0)
          throw 'Please complete all fields';
        var db = firebase.firestore();
        await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password);
        var user = firebase.auth().currentUser;

        var docRef = await db.collection('users').doc(user.uid);
        var theDoc = await docRef.get();

        var aCoTeach = '';
        if(theDoc.data().coteach)
          aCoTeach = theDoc.data().coteach;

        var aUser = {
          id: user.uid,
          name: theDoc.data().name,
          code: theDoc.data().code,
          email: theDoc.data().email,
          coteach: aCoTeach
        };
        await this.$store.commit('STORE_USER', aUser);
        this.$router.replace({ name: 'classes' });
      } catch (err) {
        console.log(err);
        if (
          err.code == 'auth/wrong-password' ||
          err.code == 'auth/user-not-found'
        )
          this.error = 'Invalid Login';
        else this.error = err;
      }
    },
    getCode() {
      var randomChars = '0123456789567abcdefghijklmnopqrstuvwxyz0123456789348';
      var result = '';
      for (var i = 0; i < 5; i++) {
        result += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        );
      }
      return result;
    },
    async googleLogin() {
      var theCode = this.getCode();
      var myCoTeach = '';
      var db = firebase.firestore();
      try {
        const provider = new firebase.auth.GoogleAuthProvider();
        var result = await firebase.auth().signInWithPopup(provider);
        var user = result.user;
        //////////////////////////////////////////////////
        var docRef = await db.collection('users').doc(user.uid);
        await docRef
          .get()
          .then(function(doc) {
            if (doc.exists) {
              theCode = doc.data().code;
              myCoTeach = doc.data().coteach;
              docRef.set(
                {
                  name: user.displayName,
                  code: theCode,
                  email: user.email,
                  teacher: 'y',
                  ch1: 'done',
                  ch2: 'done',
                  ch3: 'done',
                  ch4: 'done',
                  ch5: 'done',
                  ch6: 'done',
                  ch7: 'done',
                  ch8: 'done',
                  ch9: 'done',
                  ch10: 'done',
                  ch11: 'done',
                  ch12: 'done',
                  ch13: 'done',
                  ch14: 'done',
                  ch15: 'done',
                  ch16: 'done',
                  ch17: 'done',
                  ch18: 'done'
                },
                { merge: true }
              );
            }
          })
          .catch(function(error) {
            console.log('Error getting document:', error);
          });
        /////////////////////////////////////////////////////

        var aUser = {
          id: user.uid,
          name: user.displayName,
          code: theCode,
          email: user.email,
          coteach: myCoTeach
        };
        await this.$store.commit('STORE_USER', aUser);
        this.$router.replace({ name: 'classes' });
      } catch (err) {
        console.log(err);
        //this.error = err;
      }
    }
  }
};
</script>

<style scoped>
#spacer {
  height: 100px;
}

.error {
  color: red;
  font-size: 18px;
}

input {
  width: 400px;
  padding: 15px;
  margin: 10px;
  font-size: 20px;
}

button {
  width: 110px;
  height: 50px;
  font-size: 20px;
}

a {
  color: blue;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
}

a:hover,
a.router-link-exact-active {
  color: red;
  font-size: 16px;
}

#content {
  text-align: center;
}

#mytitle {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>

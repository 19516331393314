<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="content">
    
    <div v-if="error" class="error">{{ error }}</div>
    <div id="content" v-if="teachFirst==''">
      <form @submit.prevent="pressed">
        <input
          type="text"
          v-model="code"
          placeholder="Co-teacher access code"
        />&nbsp;&nbsp;
        <button @click="handleClick" ghost type="primary" color="blue"
          >Add Co-Teacher</button
        >
      </form>
    </div>

    <div id="content" v-if="teachFirst!=''">
      <p>
      Are you sure you want to add {{ teachFirst+ ' ' + teachLast }} as a co-teacher?
      </p>
        <button @click="addCoTeach" ghost type="primary" color="blue"
          >Continue</button
        >
    </div>
  </div> 
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';

export default {
  name: 'CoTeacherView',
  components: {
    'top-header': TopHeader
  },
  data() {
    return {
      error: '',
      code:'',
      teachFirst: '',
      teachLast: '',
      coteach: '',
    };
  },
  methods: {    
    async handleClick() {
      try {
        if (this.code.length == 0)
          throw 'Please enter a valid teacher code';
        else {
          this.teachLast='not found';
          this.error='';
          var db = firebase.firestore();
          var teacherRef = await db.collection('users');
          var theTeacher = await teacherRef
            .where('code', '==', this.code)
            .where('teacher', '==', 'y')
          let snapshot = await theTeacher.get();
          if(snapshot.empty)
            throw 'Invalid code';
          snapshot.forEach(doc => {
            this.teachFirst = doc.data().name;
            this.teachLast = doc.data().last_name;
            this.coteach = doc.data().id
          });
        }
      } catch (err) {
        console.log(err);
        this.error = err;
      }
    },
    async addCoTeach() {
      try {
        await this.$store.commit('changeCoTeach', this.code);    
        var db = firebase.firestore();
          await db
            .collection('users')
            .doc(this.$store.state.user.id)
            .set(
              {
                coteach: this.code
              },
              { merge: true }
            );
      } catch (err) {
        console.log(err);
        this.error = err;
      }
      this.$router.replace({ name: 'classes' });
    }     
  }
};
</script>

<style scoped>
#spacer {
  height: 90px;
}

#content {
  text-align: center;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  max-width: 1150px;
}

.error {
  color: red;
  font-size: 18px;
  margin-bottom: 0px;
}

input {
  width: 300px;
  padding: 8px;
  margin: 15px;
  font-size: 18px;
}

button {
  width: 150px;
  height: 35px;
  font-size: 18px;
  display: block;
  margin: 0 auto;
}

</style>

<template>
  <header>
    <nav>
      <div class="branding">
        <router-link :to="{ name: 'classes' }"
          ><img
            alt="CompuTales Logo"
            src="../assets/images/logo2.png"
            style="float:left"
          />
        </router-link>
      </div>
      <ul v-show="!mobile" class="navigation">
        <li>
          <router-link class="link" :to="{ name: 'classes' }"
            >Classes</router-link
          >
        </li>
        <li>
          <router-link class="link" :to="{ name: 'curriculum' }"
            >Curriculum</router-link
          >
        </li>
      </ul>
      <div v-show="!mobile" class="buttongroup">
        <span id="namedrop">
          Welcome
          <n-dropdown
            trigger="hover"
            size="large"
            :options="options"
            @select="handleSelect"
          >
            {{ displayName }}&#9660;
          </n-dropdown>
        </span>
      </div>
      <div class="icon">
        <img
          @click="toggleMobileNav"
          v-show="mobile"
          src="../assets/images/hamburger.png"
          :class="{ 'icon-active': mobileNav }"
        />
      </div>
      <transition name="mobile-nav">
        <ul v-show="mobileNav" class="dropdown-nav">
          <li>
            <router-link class="link" :to="{ name: 'classes' }"
              >Classes</router-link
            >
          </li>
          <li>
            <router-link class="link" :to="{ name: 'curriculum' }"
              >Curriculum</router-link
            >
          </li>
          <li>
            <router-link class="link" :to="{ name: 'changepass' }"
              >Change Password</router-link
            >
          </li>
          <li>
            <a class="link" @click="signout">Log Out</a>
          </li>
        </ul>
      </transition>
    </nav>
  </header>
</template>

<script>
import firebase from '@firebase/app';
import '@firebase/auth';
import { NDropdown } from 'naive-ui';
export default {
  name: 'Public-Header',
  components: {
    'n-dropdown': NDropdown
  },
  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    });
  },
  data() {
    return {
      scrolledNav: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null,
      loggedIn: false,
      options: [
        {
          label: 'Set-up Guide',
          key: 'guide'
        },
        {
          label: 'Change Password',
          key: 'pw'
        },
        {
          label: 'Log Out',
          key: 'logout'
        }
      ]
    };
  },
  computed: {
    displayName() {
      return this.$store.state.user.name;
    }
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1100) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    async signout() {
      try {
        await firebase.auth().signOut();
        this.$router.replace({ name: 'Home' });
      } catch (err) {
        console.log(err);
      }
    },
    handleSelect(key) {
      switch (key) {
        case 'logout':
          this.signout();
          break;
        case 'guide':
          window.open(
            this.$router.resolve({ name: 'instructions' }).href,
            '_blank'
          );
          break;
        case 'pw':
          this.$router.replace({ name: 'changepass' });
          break;
      }
    }
  }
};
</script>

<style scoped>
#namedrop {
  display: block;
  margin-top: 0px;
  color: rgb(0, 0, 0);
  font-size: 18px;
}

header {
  background-color: rgb(156, 202, 251);
  width: 100%;
  position: fixed;
  transition: 0.5s ease all;
  color: #fff;
  top: 0px;
  left: 0px;
  z-index: 50;
}

nav {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 4px 0px;
  transition: 0.5s ease all;
  width: 75%;
  margin: 0 auto;
}

ul,
.link {
  font-weight: 500;
  color: rgb(0, 0, 0);
  list-style: none;
  text-decoration: none;
}

li {
  padding: 8px;
  margin-right: 14px;
}

.link {
  font-size: 18px;
  transition: 0.5s ease all;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
}

.link:hover {
  color: blue;
  border-color: #00afea;
}

a.router-link-exact-active {
  color: blue;
  border-color: #00afea;
}

.branding {
  display: flex;
  align-items: center;
  padding: 8px 0px;
}

img {
  transition: 0.5s ease all;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttons {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.icon {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 24px;
  height: 100%;
}

.buttongroup {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 24px;
  height: 100%;
}

.icon-active {
  transform: rotate(180deg);
}

.dropdown-nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  max-width: 250px;
  height: 100%;
  background-color: rgb(195, 224, 255);
  top: 0;
  left: 0;
}

.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: 1s ease all;
}

.mobile-nav-enter-from,
.mobile-nav-leave-to {
  transform: translateX(-250px);
}

.mobile-nav-enter-to {
  transform: translateX(0);
}
</style>
